import React, {
  useContext, useState, useEffect,
  ReactElement, FormEvent, ChangeEvent,
} from 'react';
import {
  Switch, Input, Select, NumberInput, NumberInputField, NumberInputStepper,
  NumberIncrementStepper, NumberDecrementStepper, Button, Textarea,
  InputGroup, InputRightElement, IconButton,
} from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import useToast from '../../hooks/useToast';
import axios from '../../utils/axios';
import { gaTrack } from '../../services/analytics';
import { SYSTEM_LANGUAGES, TARGET_LANGUAGES } from '../../constants';
import variables from '../../variables.module.scss';
import styles from './Settings.module.scss';
import { ErrorType } from '../../types';
import useInput from '../../hooks/useInput';
import { AppContext } from '../../AppContext';
import removeButtonIcon from '../../images/remove-button.svg';
import addButtonIcon from '../../images/add-button.svg';

const inputStyle = {
  borderColor: '#67D29E90',
  _hover: {
    borderColor: '#67D29E',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#67D29E',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  color: '#F79411',
  padding: '20px',
  height: '50px',
  borderRadius: '4px',
};

const selectStyle = {
  borderColor: '#67D29E90',
  _hover: {
    borderColor: '#67D29E',
  },
  _focus: {
    borderWidth: '2px',
    borderColor: '#67D29E',
  },
  fontFamily: variables.normalFontFamily,
  fontWeight: 600,
  fontSize: '16px',
  color: '#F79411',
  height: '50px',
  borderRadius: '4px',
  iconColor: '#67D29E',
  iconSize: '14px',
};

const saveButtonStyle = {
  width: '368px',
  marginBottom: '28px',
  height: '53px',
};

const logoutButtonStyle = {
  width: '368px',
  height: '53px',
};

interface SettingsInterface {
  subMenu: ReactElement;
}

const Settings: React.FC<SettingsInterface> = ({ subMenu }) => {
  const { t } = useTranslation('account');
  const { t: translateSystemLanguage } = useTranslation('system_language');
  const { t: translateBackend } = useTranslation('backend');
  const { t: translateLanguage } = useTranslation('language');
  const [saving, setSaving] = useState(false);

  const context = useContext(AppContext);
  const toast = useToast();
  const history = useHistory();

  const currentUser = context.currentUser;

  const [dailyGoal, onDailyGoalChange, setDailyGoal] = useInput(
    50, (value: any) => value);

  const [targetLanguage, onTargetLanguageChange, setTargetLanguage] = useInput('en');
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('');
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState('');
  const [usernameErrorMsg, setUsernameErrorMsg] = useState('');

  const [isNotificationEnabled, onNotificationChange, setIsNotificationEnabled] =
    useInput(false, (event: any) => event.target.checked);

  const [firstName, onFirstNameChange, setFirstName] = useInput('');
  const [lastName, onLastNameChange, setLastName] = useInput('');
  const [username, onUsernameChange, setUsername] = useInput('');
  const [systemLanguage, onSystemLanguageChange, setSystemLanguage] = useInput('en');
  const [website, onWebsiteChange, setWebsite] = useInput('');
  const [introduction, onIntroductionChange, setIntroduction] = useInput('');
  const [keywords, , setKeywords] = useInput<string[]>([]);
  const [languages, , setLanguages] = useInput<string[]>([]);

  const onLogoutClicked = () => {
    context.removeCurrentUser();
    history.replace('/');
  };

  useEffect(
    () => {
      if (currentUser) {
        setFirstName(currentUser.firstName);
        setLastName(currentUser.lastName);
        setUsername(currentUser.username);
        setDailyGoal(currentUser.dailyGoal);
        setSystemLanguage(currentUser.systemLanguage);
        setTargetLanguage(currentUser.targetLanguage);
        setIsNotificationEnabled(currentUser.isNotificationEnabled);
        if (currentUser.recommendedUser) {
          setWebsite(currentUser.recommendedUser.website);
          setIntroduction(currentUser.recommendedUser.introduction);
          setKeywords(currentUser.recommendedUser.keywords);
          setLanguages(currentUser.recommendedUser.languages);
        }
      }
    },
    [
      currentUser,
      setFirstName,
      setLastName,
      setDailyGoal,
      setSystemLanguage,
      setTargetLanguage,
      setIsNotificationEnabled,
      setUsername,
      setWebsite,
      setIntroduction,
      setKeywords,
      setLanguages,
    ],
  );

  const onSubmit = async () => {
    try {
      setSaving(true);
      const response = await axios.put('/users/me', {
        firstName,
        lastName,
        dailyGoal,
        systemLanguage,
        targetLanguage,
        isNotificationEnabled,
        username,
        recommendedUser: currentUser?.recommendedUser ? {
          introduction,
          website,
          languages,
          keywords,
        } : undefined,
      });
      history.replace('/');
      setFirstNameErrorMsg('');
      setLastNameErrorMsg('');
      toast({
        title: t('settings_updated'),
        description: t('settings_updated_desc'),
        status: 'success',
      });
      gaTrack('Account', 'Update account');
      history.replace(`/user/${response.data.data.user.username}`);
      context.getCurrentUser();

    } catch (err) {
      const errorsMap: Record<string, ErrorType> = {};
      err.response.data.data.errors.forEach((error: ErrorType) => {
        errorsMap[error.param] = error;
      });

      if (errorsMap.firstName) {
        setFirstNameErrorMsg(errorsMap.firstName.msg);
      } else {
        setFirstNameErrorMsg('');
      }

      if (errorsMap.lastName) {
        setLastNameErrorMsg(errorsMap.lastName.msg);
      } else {
        setLastNameErrorMsg('');
      }

      if (errorsMap.username) {
        setUsernameErrorMsg(errorsMap.username.msg);
      } else {
        setUsernameErrorMsg('');
      }
    }

    setSaving(false);
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const onKeywordChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    setKeywords(keywords.map((s, i) => i === index ? event.currentTarget.value : s));
  };

  const onRemoveKeywordClicked = (index: number) => {
    setKeywords(keywords.filter((_, i) => i !== index));
  };

  const onAddKeywordClicked = () => {
    if (keywords.length < 3) {
      setKeywords([...keywords, '']);
    }
  };

  const onLanguageButtonClicked = (lang: string) => {
    if (languages.indexOf(lang) >= 0) {
      setLanguages(languages.filter(l => l !== lang));
    } else {
      setLanguages([...languages, lang]);
    }
  };

  return (
    <form
      className={classnames(
        styles.account,
        { [styles.zh]: currentUser?.systemLanguage === 'zh' })
      }
      onSubmit={onFormSubmit}
    >
      {subMenu}
      <h3 className={styles.header}>{t('personal_information')}</h3>
      <div className={styles.section}>
        <div className={styles.sectionInner}>
          <div className={styles.column}>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('username')}</h4>
              <Input
                value={username}
                placeholder={t('username')}
                onChange={onUsernameChange}
                {...inputStyle}
              />
              { usernameErrorMsg && (
                <span className={styles.errorMsg}>
                  { translateBackend(usernameErrorMsg) }
                </span>
              )}
            </div>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('your_email')}</h4>
              <div className={styles.value}>{currentUser?.email}</div>
            </div>
          </div>
          <div className={styles.columnSpacer} />
          <div className={styles.column}>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('first_name')}</h4>
              <Input
                value={firstName}
                placeholder={t('first_name')}
                onChange={onFirstNameChange} {...inputStyle}
                maxLength={12}
              />
              { firstNameErrorMsg && (
                <span className={styles.errorMsg}>
                  { translateBackend(firstNameErrorMsg) }
                </span>
              )}
            </div>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('last_name')}</h4>
              <Input
                value={lastName}
                placeholder={t('last_name')}
                onChange={onLastNameChange}
                maxLength={12}
                {...inputStyle}
              />
              { lastNameErrorMsg && (
                <span className={styles.errorMsg}>
                  { translateBackend(lastNameErrorMsg) }
                </span>
              )}
            </div>
          </div>
        </div>
        { currentUser?.recommendedUser && (
        <div className={styles.sectionInner}>
          <div className={styles.column}>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('introduction')}</h4>
              <Textarea
                value={introduction}
                placeholder={t('introduction')}
                onChange={onIntroductionChange}
                {...inputStyle}
                height="100px"
                resize="none"
                maxLength={200}
              />
            </div>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('languages_focus_in')}</h4>
              {[['en', 'fr'], ['zh', 'yue'], ['es', 'ja']].map((langList, index) => (
                <div key={index} className={styles.languagesWrapper}>
                  {langList.map((lang) => {
                    const active = languages.indexOf(lang) >= 0;
                    return <Button
                      flex="1"
                      key={lang}
                      color={active ? 'white' : 'black'}
                      borderColor="#97ACD9"
                      height="30px"
                      fontSize="14px"
                      fontWeight="normal"
                      backgroundColor={active ? '#2D65C2 !important' : 'white'}
                      variant={active ? 'solid' : 'outline'}
                      onClick={() => onLanguageButtonClicked(lang)}
                    >
                      {translateLanguage(lang)}
                    </Button>;
                  })}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.columnSpacer} />
          <div className={styles.column}>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('website')}</h4>
              <Input
                value={website}
                placeholder={t('website')}
                onChange={onWebsiteChange} {...inputStyle}
                maxLength={50}
              />
            </div>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('areas_focus_in')}</h4>
              {keywords.map((keyword, index) => (
                <InputGroup key={index} marginBottom="9px">
                  <Input
                    {...inputStyle}
                    value={keyword}
                    onChange={event => onKeywordChange(event, index) }
                    type="text"
                    maxLength={20}
                  />
                  <InputRightElement height="50px" width="50px">
                    <IconButton
                      _hover={{ filter: 'brightness(90%)' }}
                      aria-label="remove"
                      background="none"
                      width="max-content"
                      onClick={() => onRemoveKeywordClicked(index)}
                      icon={<img alt="remove" src={removeButtonIcon} />}
                    />
                  </InputRightElement>
                </InputGroup>
              ))}
              { keywords.length < 3 && (
                <IconButton
                  _hover={{ filter: 'brightness(90%)' }}
                  aria-label="add"
                  background="none"
                  width="max-content"
                  alignSelf="end"
                  marginTop="2px"
                  marginRight="6px"
                  onClick={onAddKeywordClicked}
                  icon={<img alt="add" src={addButtonIcon} />}
                />
              )}
            </div>
          </div>
        </div>
      )}
      </div>
      <h3 className={styles.header}>{t('study_settings')}</h3>
      <div className={styles.section}>
        <div className={styles.sectionInner}>
          <div className={styles.column}>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('language_you_want_to_learn')}</h4>
              <Select
                value={targetLanguage}
                onChange={onTargetLanguageChange}
                {...selectStyle}
                icon={<TriangleDownIcon />}
              >
                {TARGET_LANGUAGES.map(lang => (
                  <option value={lang} key={lang}>{translateLanguage(lang)}</option>
                ))}
              </Select>
            </div>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('system_language')}</h4>
              <Select
                value={systemLanguage}
                onChange={onSystemLanguageChange}
                {...selectStyle}
                icon={<TriangleDownIcon />}
              >
                {SYSTEM_LANGUAGES.map(lang => (
                  <option value={lang} key={lang}>{translateSystemLanguage(lang)}</option>
                ))}
              </Select>
            </div>
          </div>
          <div className={styles.columnSpacer} />
          <div className={styles.column}>
            <div className={styles.item}>
              <h4 className={styles.subHeader}>{t('daily_goal')}</h4>
              <NumberInput
                type="number"
                step={5}
                min={5}
                max={100}
                value={dailyGoal}
                onChange={onDailyGoalChange}
              >
                <NumberInputField {...inputStyle} />
                <NumberInputStepper>
                  <NumberIncrementStepper color="#67D29E" />
                  <NumberDecrementStepper color="#67D29E" />
                </NumberInputStepper>
              </NumberInput>
            </div>
            <div className={styles.item} style={{ display: 'none' }}>
              <h4 className={styles.subHeader}>{t('practice_reminder')}</h4>
              <div className={styles.dailyGoal}>
                <span>{t('daily_practice_reminder_every_morning')}</span>
                <Switch
                  size="lg"
                  isChecked={isNotificationEnabled}
                  onChange={onNotificationChange}
                  colorScheme="whatsapp"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button {...saveButtonStyle} type="submit" isLoading={saving}>
          {t('save')}
        </Button>
        <Button {...logoutButtonStyle} variant="red" onClick={onLogoutClicked}>
          {t('logout')}
        </Button>
      </div>
    </form>
  );
};

export default Settings;
